import React, { useEffect, useState } from "react";
import LiveTrafffic from "./liveTraffic";
import Metrics from "./metrics";
import KeyStats from "./keyStats";
import Overview from "./overview";
import { getDashboardData } from "../../../services/api";
import { formatDashboardData } from "./dataFormattingUtil";
import { toCamelCase } from "../../../data/utils";
import { getItemWithExpiry, setItemWithExpiry } from "../../../data/hooks";
// document.title = "dashboard";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [dashboardData, setData] = useState(
    JSON.parse(localStorage.getItem("dashboard-data")) || {}
  );
  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("dashboard-data"));
    const now = new Date();
    if (localData) {
      setData(JSON.parse(localData.value));
      if (now.getTime() > localData.expiry) {
        setSyncing(true);
        getDashboardData().then((res) => {
          if (res.status) {
            const formattedData = formatDashboardData(res?.data);
            // localStorage.setItem('dashboard-data', JSON.stringify(formattedData))
            setItemWithExpiry(
              "dashboard-data",
              JSON.stringify(formattedData),
              15
            );
            setData(formattedData);
          }
          setSyncing(false);
        });
      }
    } else {
      setLoading(true);
      getDashboardData().then((res) => {
        if (res.status) {
          const formattedData = formatDashboardData(res?.data);
          // localStorage.setItem('dashboard-data', JSON.stringify(formattedData))
          setItemWithExpiry(
            "dashboard-data",
            JSON.stringify(formattedData),
            15
          );
          setData(formattedData);
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <>
      <Overview
        data={dashboardData?.overview}
        loading={loading}
        syncing={syncing}
      />
      <KeyStats data={dashboardData?.keyStats} loading={loading} />
      {/* <Metrics data={dashboardData?.metrics} loading={loading} /> */}
      {/* <LiveTrafffic /> */}
    </>
  );
};

export default DashboardPage;
