import { useEffect, useRef } from "react";

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export const useDidMountEffectWithDebounce = (func, deps, delay) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      const timer = setTimeout(() => {
        func();
      }, delay);
      return () => clearTimeout(timer);
    } else didMount.current = true;
  }, deps);
};

export function setItemWithExpiry(key, value, expiryInMinutes) {
  const now = new Date();
  const item = {
      value,
      expiry: now.getTime() + expiryInMinutes * 60 * 1000, // Convert minutes to milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}
