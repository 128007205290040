import React, { useEffect, useState } from "react";
import Crousel from "../../Tools/Crousel";
import ListItem from "../../Tools/ListItem";
import CarDetailModel from "../../Tools/CarDetailModel";
import swal from "sweetalert";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import CarDetailModel from "../../Tools/CarDetailModel";

import { Button } from "reactstrap";
import SinglePageRight from "./singlePageRight";
import {
  checkVal,
  findUploads,
  formatPrice,
  numberWithCommas,
} from "../../../data/utils";
import { apiManager } from "../../../data/apiHandler";
import BoostrapModel from "../../Tools/BoostrapModel";
import Confirm from "../singlePendingApprovalUser/confirm";
import {
  sendApproveOrRejectMail,
  sendEmail,
  sendWhatsAppMessage,
} from "../../../services/api";
import { adMsgs } from "../../../data/translations";
import MultilingualBox from "../../Tools/MultilingualBox";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function SingleCar() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [pageloading, setPageLoading] = useState(false);
  const [params] = useSearchParams();
  const adId = params.get("id");
  const sold = params.get("sold");
  const approval = params.get("approving") === "true" ? true : false;
  const [page, setPage] = useState("normal");

  //approval modal states
  const [selectedFile, setSelectedFile] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };
  const handleApprove = (param) => {
    const url =
      param.type === "approve"
        ? `admin/ad/approve?lang=${"en"}&adId=${adId}`
        : `admin/ad/reject?lang=${"en"}&adId=${adId}`;
    apiManager("PUT", url).then((res) => {
      if (res?.status) {
        let template = adMsgs?.[param?.type]?.[param?.lang]
          .replace("{{username}}", modalData?.rawData?.owner?.fullName)
          .replace("{{message}}", param?.body);

        //     sendEmail({
        //       subject: data?.title,
        //       message: data?.body,
        //       userIds: [modalData?.rawData?.owner?._id],
        //       type: "0",
        //       attachments: selectedFile.map((v) => v?.s3URL),
        //     });

        sendWhatsAppMessage(
          {
            phoneNumber: modalData?.rawData?.owner?.phoneNumber,
            message: template,
          },
          param?.lang
        );

        sendApproveOrRejectMail({
          lang: param?.lang,
          data: {
            userId: data?.owner?._id,
            adId: adId,
            type: "rejecting_and_ad_posting", //kyc_rejection, rejecting_and_ad_posting
            message: template,
          },
        });

        swal("Success", res?.message, "success");
        setModal(false);
        navigate(-1);
      }
    });
  };
  const approveWithoutMail = (param) => {
    console.log("without mail", modalData?.type);
    const url =
      modalData.type === "approve"
        ? `admin/ad/approve?lang=${"en"}&adId=${adId}`
        : `admin/ad/reject?lang=${"en"}&adId=${adId}`;
    apiManager("PUT", url).then((res) => {
      if (res?.status) {
        sendApproveOrRejectMail({
          lang: param?.lang,
          data: {
            userId: data?.owner?._id,
            adId: adId,
            type: "rejecting_and_ad_posting", //kyc_rejection, rejecting_and_ad_posting
            message: "",
          },
        });

        swal("Success", res?.message, "success");
        setModal(false);
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageLoading(true);
    apiManager(
      "GET",
      `opp/filteredAds?lang=${"en"}&adId=${adId}${sold ? "&sold=YES" : ""}`
    )
      .then((res) => {
        if (res.status) {
          const dataT = res?.data?.ads?.[0];
          initialSetRadio(dataT, dataT?.adTypeName?.en === "Sell" ? "normal" : "rental")
          const title =
            dataT?.properties?.basicDetails?.make?.["en"] +
            " " +
            dataT?.properties?.basicDetails?.model?.["en"] +
            " " +
            dataT?.properties?.basicDetails?.makeYear;
          document.title = title;
          setData(dataT);
          setPage(dataT?.adTypeName?.en === "Sell" ? "normal" : "rental");
        } else {
          console.log(res);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  }, [params.get("adId")]);

  const [day, setDay] = useState(page);

  const handleChange = (e) => {
    setRadio(e.target.value);
    setDay(e.target.value);
  };

  const getPrice = (currency) => {
    if (currency === "IQD") {
      switch (day) {
        case "normal":
          if (!checkVal(data?.askPrice)) return "";
          return `${formatPrice("en", data?.askPrice)}`;
        case "daily":
          if (!checkVal(data?.askPrice)) return "";
          return `${formatPrice("en", data?.askPrice)}/${"Daily"}`;
        case "weekly":
          if (!checkVal(data?.askPrice1)) return "";
          return `${formatPrice("en", data?.askPrice1)}/${"Weekly"}`;
        case "monthly":
          if (!checkVal(data?.askPrice2)) return "";
          return `${formatPrice("en", data?.askPrice2)}/${"Monthly"}`;
        case "rental":
          if (!checkVal(data?.askPrice)) return "";
          return `${formatPrice("en", data?.askPrice)}/${"Daily"}`;
      }
    } else {
      switch (day) {
        case "normal":
          if (!checkVal(data?.dollorAskPrice)) return "";
          return `$${numberWithCommas(data?.dollorAskPrice)}`;
        case "daily":
          if (!checkVal(data?.dollorAskPrice)) return "";
          return `$${numberWithCommas(data?.dollorAskPrice)}/${"Daily"}`;
        case "weekly":
          if (!checkVal(data?.dollorAskPrice1)) return "";
          return `$${numberWithCommas(data?.dollorAskPrice1)}/${"Weekly"}`;
        case "monthly":
          if (!checkVal(data?.dollorAskPrice2)) return "";
          return `$${numberWithCommas(data?.dollorAskPrice2)}/${"Monthly"}`;
        case "rental":
          if (!checkVal(data?.dollorAskPrice)) return "";
          return `$${numberWithCommas(data?.dollorAskPrice)}/${"Daily"}`;
      }
    }
  };

  const [radioState, setRadio] = useState("daily");
  const initialSetRadio = (data, page) => {
    if (page === "rental") {
      if (checkVal(data?.askPrice) || checkVal(data?.dollorAskPrice)) {
        setRadio("daily");
        setDay("daily");
      } else if (checkVal(data?.askPrice1) || checkVal(data?.dollorAskPrice1)) {
        setRadio("weekly");
        setDay("weekly");
      } else {
        setRadio("monthly");
        setDay("monthly");
      }
    }
  };

  const [readMore, setReadMore] = useState(false);

  const [chatNow, setChatNow] = useState({
    loading: false,
    adId: "",
  });

  const displayData = {
    size:
      data?.properties?.specifications?.engineSize ||
      data?.properties?.specifications?.batterySize ||
      "",
    sizeUnit: data?.properties?.specifications?.engineSize ? "L" : "kWh",
    fuel: data?.properties?.specifications?.fuelType?.["en"] || "",
    bodyType: data?.properties?.specifications?.bodyType?.["en"] || "",
    km:
      data?.properties?.specifications?.km ||
      data?.properties?.specifications?.km == 0
        ? data?.properties?.specifications?.km
        : "",
    gearbox: data?.properties?.specifications?.gearbox?.["en"] || "",
    doors: data?.properties?.specifications?.doors,
    seats: data?.properties?.specifications?.seats,
  };

  return pageloading ? (
    <div
      class="d-flex justify-content-center"
      style={{ minHeight: "100vh", marginTop: "25vh" }}
    >
      <div class="spinner-grow" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <section
        class="singlepage"
        style={{ paddingTop: "0px", borderTop: "0px" }}
      >
        <div class="container">
          <h6 class="back d-flex justify-content-between">
            <a onClick={() => navigate(-1)}>
              <img src="../assets/images/backbt.png" /> {"Go back"}
            </a>
          </h6>
          <div className="row d-flex justify-content-end mb-2">
            <div className="col-6 d-xs-none d-block"></div>
            <div className="col-6 col-sm-4 col-lg-3 col-xl-2 my-2">
              <a
                className="w-100 themeButton d-flex justify-content-center align-items-center"
                style={{ marginLeft: "0px", textDecoration: "none" }}
                onClick={() => navigate(`/edit_advert?id=${adId}`)}
              >
                Edit Advert
              </a>
            </div>
            {approval && (
              <>
                <div className="col-6 col-sm-4 col-lg-3 col-xl-2 my-2">
                  <a
                    className="w-100 themeButtonNo d-flex justify-content-center align-items-center"
                    style={{ marginLeft: "0px", textDecoration: "none" }}
                    onClick={() =>
                      openModal({
                        type: "reject",
                        key: "Reject Advert..!?",
                        rawData: data,
                      })
                    }
                  >
                    Reject Advert
                  </a>
                </div>
                <div className="col-6 col-sm-4 col-lg-3 col-xl-2 my-2">
                  <a
                    className="w-100 themeButtonYes d-flex justify-content-center align-items-center"
                    style={{ marginLeft: "0px", textDecoration: "none" }}
                    onClick={() =>
                      openModal({
                        type: "approve",
                        key: "Approve Advert..!?",
                        rawData: data,
                      })
                    }
                  >
                    Approve Advert
                  </a>
                </div>
              </>
            )}
          </div>
          <div class="">
            <div className="col-lg-12 card mb-3 p-3">
              <Crousel data={data ? findUploads(data) : []} />
            </div>
            <div className="col-lg-12">
              <SinglePageRight
                classNames={"singlepage_right d-sm-block d-none"}
                page={page}
                data={data}
                radioState={radioState}
                handleChange={handleChange}
                getPrice={getPrice}
                lang={"en"}
                params={params}
                //   handleInitiateChat={handleInitiateChat}
                chatNow={chatNow}
                setChatNow={setChatNow}
              />
            </div>
            <div class="col-lg-12">
              <div
                class="singlepage_left"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  marginRight: "0px",
                }}
              >
                <SinglePageRight
                  classNames={"singlepage_right d-sm-none d-block"}
                  page={page}
                  data={data}
                  radioState={radioState}
                  handleChange={handleChange}
                  getPrice={getPrice}
                  lang={"en"}
                  params={params}
                  // handleInitiateChat={handleInitiateChat}
                  chatNow={chatNow}
                  setChatNow={setChatNow}
                />
                <h5 style={{ position: "unset" }} className="mt-3">
                  {"Car Details"}
                </h5>
                {data?.properties?.specifications?.km ? (
                  <h6 className="d-flex justify-content-between align-items-center flex-wrap">
                    <span className="d-flex align-items-center mb-3">
                      <img src="../assets/images/adt.png" align="icon" />{" "}
                      {`${
                        numberWithCommas(data?.properties?.specifications?.km) +
                        " " +
                        "KMs"
                      }`}
                    </span>
                    {data?.brandNew && (
                      <div className="d-flex justify-content-end align-items-center">
                        <span
                          style={{ color: "#212529" }}
                          className=" d-flex align-items-cneter mb-3"
                        >
                          <Icon
                            fontSize={24}
                            icon="fluent:new-16-filled"
                            style={{ color: "#FFDE21" }}
                          />
                          Brand new
                        </span>
                      </div>
                    )}
                  </h6>
                ) : (
                  <></>
                )}

                <div className="mb-3">
                  {/* <span style={{ fontSize: "22px", fontWeight: 500 }}>City: </span> */}
                  <span style={{ fontSize: "22px", fontWeight: 500 }}>
                    {data?.city}
                  </span>
                </div>
                <h6>
                  <span
                    style={{
                      color: "#212529",
                    }}
                    className=" rounded-1 mb-3  d-flex flex-column"
                  >
                    {data?.properties?.plateType && (
                      <span className="" style={{ fontSize: "16px" }}>
                        Plate type - {data?.properties?.plateType}
                      </span>
                    )}
                    {data?.properties?.registrationNumber && (
                      <span className="" style={{ fontSize: "20px" }}>
                        Plate number - {data?.properties?.registrationNumber}
                      </span>
                    )}
                  </span>
                </h6>

                <ul class="hatchback">
                  {displayData?.bodyType && (
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil1.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {displayData?.bodyType}
                          </span>
                        </>
                      }
                    />
                  )}
                  {displayData?.fuel && (
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil5.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {displayData?.fuel}
                          </span>
                        </>
                      }
                    />
                  )}
                  {displayData?.size && (
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil2.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {displayData?.size + " " + displayData?.sizeUnit}
                          </span>
                        </>
                      }
                    />
                  )}
                  {displayData?.gearbox && (
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil3.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {displayData?.gearbox}
                          </span>
                        </>
                      }
                    />
                  )}
                  {displayData?.doors && (
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil4.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {displayData?.doors} {"Doors"}
                          </span>
                        </>
                      }
                    />
                  )}
                  {displayData?.seats && (
                    <ListItem
                      key={0}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <img src={"../assets/images/mil6.png"} align="icon" />
                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                            }}
                          >
                            {displayData?.seats} {"Seats"}
                          </span>
                        </>
                      }
                    />
                  )}
                </ul>
                <MultilingualBox
                  title={
                    <h5 class="mb-3 d-flex align-items-center">Description</h5>
                  }
                  valueObj={data?.properties?.features?.carDescription}
                />

                {/* <p>
                  {data?.properties?.features?.carDescription?.["en"] &&
                  data?.properties?.features?.carDescription?.["en"]?.charAt(
                    0
                  ) !== "[" ? (
                    <>
                      <h5 class="mb-3">{"Description"}</h5>
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: readMore ? 100 : 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          // idth: "350px",
                        }}
                      >
                        {data?.properties?.features?.carDescription?.["en"]
                          ? data?.properties?.features?.carDescription?.["en"]
                          : "--"}
                      </p>
                      {data?.properties?.features?.carDescription?.["en"]
                        ?.length > 190 && (
                        <a
                          className="mb-3"
                          onClick={() => {
                            setReadMore(!readMore);
                          }}
                        >
                          {readMore ? "See less" : "See more"}
                        </a>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </p>
                {data?.properties?.features?.carDescription?.["en"]?.length >
                  190 && (
                  <a
                    className="mb-3"
                    onClick={() => {
                      setReadMore(!readMore);
                    }}
                  >
                    {readMore ? "See less" : "See more"}
                  </a>
                )} */}
                <h5
                  class="spe"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {"Specifications"}{" "}
                  <img src="../assets/images/aron.png" align="aro" />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {data?.properties && (
        <CarDetailModel
          data={data?.properties ? data?.properties : []}
          title={"Specifications"}
        />
      )}

      <BoostrapModel
        show={modal}
        heading={modalData?.key}
        style={"warning warningKyc "}
        fullWidth
        component={
          <Confirm
            data={modalData}
            handleSubmit={handleApprove}
            closeModal={handleCloseModal}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setStatusWithoutMail={approveWithoutMail}
            preferredLang={data?.owner?.preferredLanguage}
          />
        }
        onHide={() => setModal(false)}
      />
    </>
  );
}
